<template>
    <HelloWorld/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
    name: 'App',
    components: {
        HelloWorld
    }
}
</script>

<style>
*{
    background-color: #FEC7B4;
}
</style>
